@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
}

html {
    scroll-behavior: smooth;
}

.bold {
    font-weight: 700;
}

.green {
    color:#8DC73F;
}

#home {
    position: relative;
}

button {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 20px 60px;
    color: #8DC73F;
    border: 1px solid #8DC73F;
    transition: all 300ms ease;
    cursor: pointer;
}

button:hover {
    background-color: #8DC73F;
    color: white;
}

.black__line {
    width: 50px;
    height: 3px;
    border: none;
    background-color: black;
    margin: 5px auto 40px auto;
}

/* NAV */

nav {
    width: 100%;
    padding: 20px 10px 10px 10px;
    position: absolute;
    width: 100%;
}

.nav__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
}

.nav__logo--wrapper {
    position: relative;
    width: 200px;
    height: 50px;
}

.ramadan__icon {
    position: absolute;
    left: -58px;
    top: 48px;
    width: 150px;
}

.nav__logo {
    width: 100%;
    height: 100%;
    filter: brightness(0);
    /* text-decoration: none; */
    /* text-transform: uppercase; */
    /* font-weight: 800; */
    /* color: black; */
    cursor: pointer;
    transition: all 300ms ease;
}

.nav__logo:hover {
    transform: scale(0.97);
}

.nav__link {
    color: black;
    text-decoration: none;
    margin: 0 15px;
    text-transform: uppercase;
    font-size: 14px;
    opacity: 0.5;
    transition: all 300ms ease;
}

.nav__link:hover {
    opacity: 1;
}

/* HEADER */

.header {
    height: 100vh;
    background: linear-gradient(rgba(240, 240, 240, 0.95), rgba(240, 240, 240, 0.95)), url("https://wallpapercave.com/wp/wp2003573.jpg");
    background-size: cover;
    background-attachment: fixed;
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
}

.header__container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 70%;
    margin: 0 auto;
}

.header__logo {
    width: 300px;
    height: 75px;
    filter: brightness(0) invert(1);
}

.header__img {
    margin-right: 100px;
}

.header__loc {
    font-weight: 100;
    text-transform: uppercase;
}

.header__title {
    position: relative;
    color: black;
    font-size: 50px;
    margin: 20px 0 5px 0;
    font-weight: 700;
    text-transform: capitalize;
    width: fit-content;
}

.fa-quote-left {
    position: absolute;
    top: -20px;
    left: -30px;
    color:#8DC73F;
    font-size: 30px;
}

.fa-quote-right {
    position: absolute;
    bottom: -10px;
    right: -35px;
    font-size: 30px;
}

.header__sub-title {
    color: black;
    margin: 30px 0;
}

.header__title--upper {
    background-color: rgb(36, 138, 36);
}

.header__title--lower {
    color: rgb(36, 138, 36);
    background-color: white;
}

/* ABOUT */

.about {
    width: 100%;
    /* background-color: #8DC73F; */
    padding: 50px;
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
}

.about__container {
    width: 60%;
    margin: 0 auto;
}

.about__title {
    text-align: center;
    color: black;
}

.about__text {
    margin-top: 30px;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.459);
    text-align: center;
}

/* --------- */

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
}

.separator__icon {
    font-size: 30px;
    color: #8DC73F;
    margin: 0 20px;
}

.separator__left, .separator__right {
    width: 200px;
    height: 1px;
    border: none;
    background-color: #8cc73f63;
}

/* SECTIONS */

.sections {
    width: 100%;
    padding: 50px;
}

.sections__container {
    width: 60%;
    margin: 0 auto;
}

.sections__title {
    text-align: center;
    /* margin-bottom: 20px; */
}

.sections__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
}

.sections__card {
    /* width: 225px; */
    padding: 20px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
}

.sections__card:nth-child(even) {
    flex-direction: row-reverse;
}

.sections__card--img {
    min-width: 300px;
    min-height: 300px;
    max-width: 300px;
    max-height: 300px;
    border-radius: 50%;
}

.sections__card:nth-child(odd) .sections__card--img {
    margin-right: 50px;
}

.sections__card:nth-child(even) .sections__card--img {
    margin-left: 50px;
}

.sections__card--text {
    /* font-size: 16px; */
}

.sections__card--title {
    font-size: 30px;
}

.sections__card--para {
    margin: 20px 0;
    color: rgba(0, 0, 0, 0.3);
}


/* --------- */

/* --------- */

.partners {
    width: 100%;
    padding: 50px;
}

.partners__container {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partners__list {
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* align-items: center; */
    /* justify-content: center; */
}

.partners__upper, .partners__lower {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
}

.partner__img {
    width: 150px;
    height: 65px;
    margin: 20px;
    filter: brightness(0);
    opacity: 0.3;
}

/* --------- */

/* FOOTER */

footer {
    background: linear-gradient(rgba(240, 240, 240, 0.95), rgba(240, 240, 240, 0.95)), url("https://wallpapercave.com/wp/wp2003573.jpg");
    background-size: cover;
    background-attachment: fixed;
    padding: 40px 0 10px 0;
}

.footer__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__logo {
    width: 200px;
    height: 50px;
    filter: brightness(0);
    cursor: pointer;
    transition: all 300ms ease;
}

.footer__logo:hover {
    transform: scale(0.97);
}

.footer__cr {
    font-size: 12px;
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.5);
}

/* CONTACT */

.ending__line {
    width: 60%;
    margin: 0 auto;
    border: none;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.253);
}

.contact {
    width: 100%;
    padding: 50px;
}

.contact__container {
    width: 80%;
    margin: 0 auto;
}

.contact__title {
    text-align: center;
    /* margin-bottom: 20px; */
}

.contact__text {
    margin-top: 50px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.contact__icon {
    font-size: 30px;
    color: #8DC73F;
    /* margin-bottom: 5px; */
    margin-bottom: 20px;
}

/* .contact__section {
    color: #8DC73F;
    margin-bottom: 10px;
    font-size: 25px;
} */

.contact__phone, .contact__address, .contact__email {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 30px;
}

.contact__phone--elem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact h6 {
    margin-bottom: 5px;
    font-size: 18px;
    opacity: 0.55;
}

.contact a, .contact__address p {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
}

/* ---------- */

/* NEWS */

.news {
    width: 100%;
    padding: 50px;
}

.news__container {
    width: 60%;
    margin: 0 auto;
}

.news__title {
    text-align: center;
    /* margin-bottom: 20px; */
}

.news__list {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.news__elem {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    align-self: flex-start;
}

.news__elem--img {
    min-width: 300px;
    max-width: 300px;
    min-height: 150px;
    max-height: 150px;
    margin-right: 20px;
}

.news__elem--title {
    font-size: 20px;
}

.news__elem--date {
    margin: 10px 0;
    font-size: 14px;
    color:rgba(0, 0, 0, 0.4);
    font-style: italic;
}

.news__line {
    align-self: center;
}

/* --------- */

/* FORM */

.contact__message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

form {
    display: flex;
    flex-direction: column;
    width: 49%;
    margin-right: 20px;
}

form h1 {
    font-size: 30px;
    margin-bottom: 15px;
}

form label {
    font-weight: 600;
    opacity: 0.5;
}

form input, textarea {
    outline: none;
    border: none;
    background-color: rgb(239, 239, 239);
    padding: 10px;
    margin: 5px 0 15px 0;
    color: black;
}

form textarea {
    height: 100px;
}

form button {
    margin-top: 15px;
}

.form__success {
    color:#8DC73F;
    font-size: 22px;
    margin-top: 10px;
    font-weight: 700;
}

.map__wrapper {
    width: 49%;
}

.map__img, video {
    margin-top: 40px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

video {
    object-fit: fill;
}

/* --------- */

/* RESPONSIVENESS */

@media (max-width: 768px) {
    .ramadan__icon {
        width: 100px;
        left: -30px;
    }

    .nav__container {
        justify-content: center;
    }

    .nav__links {
        display: none;
    }

    .header__container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .header__title {
        margin: 20px auto 0 auto;
    }

    .header__text {
        text-align: center;
    }

    .header__img {
        display: none;
    }

    .sections__cards {
        flex-direction: column;
        align-items: center;
    }

    .sections__card {
        flex-direction: column;
    }

    .sections__card--img {
        margin-bottom: 20px;
    }

    .sections__card {
        border: 1px solid rgba(0, 0, 0, 0.079);
        flex-direction: column !important;
        align-items: center;
        text-align: center;
        margin-bottom: 10px;
    }

    .sections__card:nth-child(odd) .sections__card--img {
        margin-right: 0;
    }

    .sections__card:nth-child(even) .sections__card--img {
        margin-left: 0;
    }

    .news__elem {
        flex-direction: column;
        align-self: center;
        text-align: center;
    }

    .news__elem--img {
        margin-right: 0;
    }

    .news__elem--text {
        margin-top: 10px;
    }

    .partner__img {
        width: 100px;
        height: 40px;
        margin: 10px;
    }

    .contact__text {
        flex-direction: column;
    }

    .fa-envelope, .fa-phone {
        margin-top: 40px;
    }

    .contact__message {
        flex-direction: column;
    }

    form {
        width: 100%;
    }

    form h1 {
        margin-top: 50px;
        text-align: center;
    }

    .map__wrapper {
        width: 100%;
    }
}

/* --------- */